<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-row class="align-center justify-space-between">
            <v-col cols="12" sm="4" md="3">
              <month-picker v-model="selectedDate" />
            </v-col>
            <v-col cols="12" sm="8" md="9" class="text-right">
              <id-button v-if="hasEmployees" @click="onMultipleShiftDeleteBtnClick" class="mr-2">
                <template #icon>
                  <v-icon left>mdi-plus</v-icon>
                </template>
                {{ $t("time.calendar.multiple_shift_deleting") }}
              </id-button>
              <id-button @click="refresh">
                <template #icon>
                  <v-icon left>mdi-refresh</v-icon>
                </template>
                {{ $t("buttons.refresh") }}
              </id-button>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-0">
          <calendar :date="selectedDate" :employees="employees" :total-hours="totalHours" :workplans="workplans" />
          <loading v-model="loading" />
          <multiple-shift-delete-dialog
            v-model="multipleDeleteDialogStatus"
            :employees="employees"
            :date="selectedDate"
            @delete:workplan="refresh"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { FILTER_EMPLOYEES, FILTER_WORKPLANS } from "./gql";
  export default {
    name: "EmployeeCalendar",
    components: {
      Calendar: () => import("./components/calendar"),
      MonthPicker: () => import("./components/MonthPicker"),
      Loading: () => import("./components/Loading"),
      EmployeePicker: () => import("./components/EmployeePicker"),
      MultipleShiftDeleteDialog: () => import("./components/MultipleShiftDeleteDialog")
    },
    data: vm => ({
      selectedDate: vm.$moment().format("YYYY-MM"),
      employees: [],
      workplans: [],
      totalHours: [],
      loading: false,
      multipleDeleteDialogStatus: false
    }),
    computed: {
      currentYear() {
        return this.$moment(this.selectedDate, "YYYY-MM").format("YYYY");
      },
      currentMonth() {
        return this.$moment(this.selectedDate, "YYYY-MM").format("MM");
      },
      startOfMonth() {
        const date = this.selectedDate || this.$moment().format("YYYY-MM");
        return this.$moment(date)
          .startOf("month")
          .format("YYYY-MM-DD");
      },
      endOfMonth() {
        const date = this.selectedDate || this.$moment().format("YYYY-MM");
        return this.$moment(date)
          .endOf("month")
          .format("YYYY-MM-DD");
      },
      hasEmployees() {
        return this.employees.length > 0;
      }
    },
    watch: {
      async selectedDate() {
        this.loading = true;
        await this.fetchWorkplans();
        this.loading = false;
      }
    },
    methods: {
      onMultipleShiftDeleteBtnClick() {
        this.multipleDeleteDialogStatus = true;
      },
      async refresh() {
        this.loading = true;
        await this.fetchWorkplans();
        this.loading = false;
      },
      async fetchEmployees() {
        await this.$apollo
          .query({
            query: FILTER_EMPLOYEES,
            fetchPolicy: "no-cache",
            variables: {
              criteria: {
                shiftManager: this.$store.state.auth.user.id
              }
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employees = data.filterEmployees;
            }
          })
          .catch(console.error);
      },
      async fetchWorkplans() {
        if (this.hasEmployees) {
          await this.$apollo
            .query({
              query: FILTER_WORKPLANS,
              fetchPolicy: "no-cache",
              variables: {
                criteria: {
                  employees: this.employees.map(({ id }) => id),
                  beginDate: this.startOfMonth,
                  endDate: this.endOfMonth
                }
              }
            })
            .then(({ data, errors }) => {
              if (!data.error && !errors) {
                this.setWorkplans(data.filterWorkplans);
                this.setTotalHours(data.filterWorkplans);
              }
            })
            .catch(console.error);
        }
      },
      setWorkplans(workplans) {
        this.workplans = this.employees.map(employee =>
          workplans.filter(workplan => workplan.employee.id === employee.id)
        );
      },
      setTotalHours(workplans) {
        this.totalHours = this.employees.map(employee => {
          let totalHour = 0;
          workplans
            .filter(workplan => workplan.employee.id === employee.id)
            .forEach(workplan => {
              const assignedShift =
                Array.isArray(workplan.assignedShifts) && workplan.assignedShifts.length > 0
                  ? workplan.assignedShifts[0]
                  : null;
              if (assignedShift) {
                totalHour += assignedShift.expectedWorkingTimeInMinutes || 0;
              }
            });
          return Number(totalHour / 60).toFixed(1);
        });
      },
      workplanDeleteHandler(workplanId, employeeId) {
        const employeeIndex = this.employees.findIndex(employee => employee.id === employeeId);
        if (employeeIndex > -1) {
          const workplanIndex = this.workplans[employeeIndex].findIndex(workplan => workplan.id === workplanId);
          if (workplanIndex > -1) {
            this.workplans[employeeIndex].splice(workplanIndex, 1);
          }
        }
      },
      workplanSaveHandler() {
        this.refresh();
      }
    },
    async created() {
      this.loading = true;
      await this.fetchEmployees();
      await this.fetchWorkplans();
      this.loading = false;
    },
    mounted() {
      this.$eventBus.$on("delete:workplan", this.workplanDeleteHandler);
      this.$eventBus.$on("save:workplan", this.workplanSaveHandler);
    },
    beforeDestroy() {
      this.$eventBus.$off("delete:workplan");
      this.$eventBus.$off("save:workplan");
    }
  };
</script>
