import gql from "graphql-tag";

export const FILTER_EMPLOYEES = gql`
  query filterEmployees($criteria: EmployeeFilterCriteria!) {
    filterEmployees(criteria: $criteria) {
      id
      firstName
      lastName
      profile {
        imageUrl
      }
      employment {
        branch {
          id
          name
          color
        }
      }
    }
  }
`;

export const FILTER_WORKPLANS = gql`
  query filterWorkplans($criteria: WorkplanFilterCriteria!) {
    filterWorkplans(criteria: $criteria) {
      id
      date
      breakDay
      countAsOvertime
      locked
      employee {
        id
        firstName
        lastName
      }
      assignedShifts {
        id
        name
        color
        shiftType
        beginTime
        endTime
        lengthInMinutes
        expectedWorkingTimeInMinutes
        latelyClockInTolerance
        earlyClockOutTolerance
        maxClockInOvertimeInMinutes
        maxClockOutOvertimeInMinutes
        totalWorkingTimeInMinutes
        totalBreakTimeInMinutes
      }
      leaveRequest {
        leaveType {
          name
          color
          icon
        }
      }
      calculatedTimesheet {
        shiftBeginDateTime
        shiftEndDateTime
        clockInTimestamp
        clockOutTimestamp
      }
      overtimes {
        overtimeInMinutes
        period
        requestStatus
      }
    }
  }
`;

export const SHIFTS = gql`
  {
    shifts {
      id
      name
      color
      code
      beginTime
      endTime
      shiftType
      lengthInMinutes
      maxClockInOvertimeInMinutes
      minClockInOvertimeInMinutes
      latelyClockInTolerance
      earlyClockOutTolerance
      minClockOutOvertimeInMinutes
      maxClockOutOvertimeInMinutes
      minOvertimeInMinutes
      maxOvertimeInMinutes
      breaks {
        id
        name
        beginTime
        durationInMinutes
        nextDay
      }
      bonusBeginTime
      bonusDurationInMinutes
      minBonusTimeInMinutes
    }
  }
`;

export const FILTER_HOLIDAYS = gql`
  query filterHolidays($criteria: HolidayFilterCriteria!) {
    filterHolidays(criteria: $criteria) {
      id
      name
      color
      code
      countAsOvertime
      date
    }
  }
`;
